<template>
  <v-overlay v-model="internalShowing" contained content-class="h-100 w-100" scrim="surface">
    <v-container class="d-flex justify-center align-center h-100 w-100 flex-column">
      <v-progress-circular indeterminate :size="64" :width="6" />
      <p>{{ label }}</p>
    </v-container>
  </v-overlay>
</template>

<script setup lang="ts">
const props = defineProps<{
  showing?: boolean
  label?: string
}>()
const { showing, label } = toRefs(props)

const internalShowing = ref(false)

watch(
  showing,
  (val) => {
    internalShowing.value = val
  },
  { immediate: true },
)
</script>
